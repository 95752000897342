import React, { ComponentProps, MouseEventHandler, RefObject } from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import Marquee from '../components/Marquee';
import Sieve from '../components/Sieve';
import Thickener from '../components/Thickener';

const GET_UPDATES_ENDPOINT = process.env.REACT_APP_GET_UPDATES_ENDPOINT;

interface GetUpdatesState {
    sending: boolean;
    success: boolean;
    error: boolean;
}

class GetUpdates extends React.Component<ComponentProps<typeof GetUpdates>, GetUpdatesState> {
    readonly form: RefObject<HTMLFormElement> = React.createRef<HTMLFormElement>();
    readonly email: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    state: GetUpdatesState = {
        sending: false,
        success: false,
        error: false
    };

    submitForm: MouseEventHandler = (e) => {
        const form = this.form.current;
        const email = this.email.current;

        if (!GET_UPDATES_ENDPOINT || !form || !email || !form.reportValidity()) {
            console.log(GET_UPDATES_ENDPOINT);
            return;
        }

        e.preventDefault();

        this.setState({
            sending: true,
            success: false,
            error: false
        });

        fetch(GET_UPDATES_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.email.current?.value
            })
        }).then((response: Response) => {
            if(response.status !== 200) {
                this.setState({
                    sending: false,
                    success: false,
                    error: true
                });

                return;
            }

            this.setState({
                sending: false,
                success: true,
                error: false
            });
        }).catch((error) => {
            this.setState({
                sending: false,
                success: false,
                error: true
            });

            console.error(error);
        });
    }

    render() {
        return (
            <section id="GetUpdates">
                <div className="grid">
                    <article>
                        <div className="back">
                            <Link className="back" to="/">← Back</Link>
                        </div>

                        <div className="content"></div>
                    </article>

                    <article className="smaller">
                        <p>Leave us your electronic whereabouts in the field below and we’ll keep you informed about developments concerning Senga.</p>
                        <p>The data provided here shall be used for the purposes of beta-tests informational updates only.</p>
                    </article>

                    <figure>
                        <Sieve />
                    </figure>

                    <form ref={ this.form }>
                        { this.state.sending && !this.state.success && <div className="alert sending">
                            <Thickener as="strong" content="Sending..." delay={ 400 } />
                        </div> }

                        { this.state.success && <div className="alert success">
                            <div className="message">
                                <Thickener as="strong" content="Thank you!" delay={ 400 } />
                                <Thickener as="strong" content="We received your address." delay={ 1200 } />
                            </div>
                        </div> }

                        { this.state.error && <div className="alert error">
                            <div className="message">
                                <Thickener as="strong" content="Sorry, an error occurred." delay={ 400 } />
                                <Thickener as="strong" content="Please try again later." delay={ 1200 } />
                            </div>
                        </div> }

                        <label>E-mail address</label>
                        <input type="email" ref={ this.email } placeholder="Type here" required />

                        <Button
                            action={ this.submitForm }
                            content="Submit"
                            arrow={{
                                direction: "right",
                                align: "right"
                            }}
                        ></Button>
                    </form>
                </div>

                <Marquee
                    content="Are you interested in, and concerned about, the issues of data privacy? Do you feel you can trust today’s social media platforms? Would you say that a statement like “The use of today’s social media is free of charge” is entirely true? When using social media platforms, do you ever feel like you’re being treated as a commodity? Are you aware that uploading content to today’s widespread platforms means not being the actual owner and operator of the uploaded content? Do you think it’s a problem? Do you agree that today’s social media need innovation? Do you wish social media were more authentic? Would you say that a common goal, one of reinstituting genuine privacy and ownership, could bring you closer to other users and creators?"
                    duration="60s"
                />
            </section>
        );
    }
}

export default GetUpdates;
